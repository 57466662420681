import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_text_copy = _resolveComponent("tm-text-copy")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_forms_dropdown = _resolveComponent("forms-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, null, {
    "body-cell-embed-code": _withCtx(({ row }) => [
      _createVNode(_component_tm_text_copy, {
        color: "transparent",
        class: "w100pr",
        "full-width": "",
        text: row.embedCode
      }, null, 8, ["text"])
    ]),
    "body-cell-name": _withCtx(({ row }) => [
      _createVNode(_component_router_link, {
        class: "d-flex align-center font-weight-normal emphasize--text",
        to: _ctx.routerLink
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_icon, {
            name: "tmi-form",
            size: "xLarge",
            class: "mr-2 distinct--text"
          }),
          _createTextVNode(" " + _toDisplayString(row.name), 1)
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-actions": _withCtx(() => [
      _createVNode(_component_forms_dropdown)
    ]),
    _: 1
  }))
}