
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmTableActionButton,
    TmDropdown,
    TmDropdownItem,
  },
  setup() {
    const { openModal } = useModals()
    const openDeleteModal = () => {
      openModal('confirmation', {
        title: 'Delete form',
        text: 'The selected form will be permanently deleted. Are you sure you would like to delete this form?',
        btnText: 'Delete',
        btnColor: 'red',
      })
    }

    return {
      openDeleteModal,
    }
  },
})
