
import { defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useFormsOpenModal } from '@/compositions/services/useFormsOpenModal'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    TmButton,
    TopFilter,
  },
  props: {
    formType: {
      type: String as PropType<'click-to-text' | 'subscribe'>,
      default: 'click-to-text',
    },
  },
  setup(props) {
    const { openAddNewModal, openDeleteModal, openAddNewSubscribeModal } = useFormsOpenModal()
    const handleAddNew = () => props.formType === 'subscribe' ? openAddNewSubscribeModal() : openAddNewModal()

    return {
      openDeleteModal,
      handleAddNew,
    }
  },
})
