
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';
import type { SizeProp, ButtonColor } from '@/definitions/shared/types'
import kebabCase from 'lodash/kebabCase'

export default defineComponent({
  components: {
    TmButton,
    TmTooltip,
  },
  props: {
    hideBtn: {
      type: Boolean,
    },
    fullWidth: {
      type: Boolean,
    },
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String as PropType<ButtonColor>,
    },
    size: {
      type: String as SizeProp<'' | 'large'>,
      default: '',
    },
  },
  setup(props) {
    const copiedStatus = ref(false)
    const copyText = () => {
      copiedStatus.value = true
      copyToClipboard(props.text)
    }
    return {
      copiedStatus,
      copyText,
      kebabCase,
    }
  },
})
