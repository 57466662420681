
import type { PropType } from 'vue'
import { defineComponent, computed } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import FormsDropdown from '@/components/pages/services/forms/FormsDropdown.vue'
import TmTextCopy from '@/components/shared/TmTextCopy.vue'

export default defineComponent({
  components: { TmTextCopy, FormsDropdown, TmTable },
  props: {
    formType: {
      type: String as PropType<'click-to-text' | 'subscribe'>,
      default: 'click-to-text',
    },
  },
  setup(props) {
    const routerLink = computed(() => {
      if (props.formType === 'click-to-text') {
        return {
          name: 'base.services.forms.edit',
          params: { id: '1' },
        }
      }

      return {
        name: 'base.services.forms.subscribe.edit',
        params: { id: '1' },
      }
    })

    return {
      routerLink,
    }
  },
})
