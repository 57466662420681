import { useModals } from '@/compositions/modals'

type Forms = () => {
  openAddNewModal: () => void;
  openAddNewSubscribeModal: () => void;
  openDeleteModal: () => void;
}

export const useFormsOpenModal: Forms = () => {
  const { openModal } = useModals()

  const openAddNewSubscribeModal = () => {
    openModal('servicesNewSubscribe')
  }

  const openAddNewModal = () => {
    openModal('grayTileButtons', {
      modalTitle: 'Add new form',
      items: [
        {
          icon: 'email',
          label: 'Click to text',
          description: 'Allows people to contact you directly from your website',
          to: { name: 'base.services.forms.new' },
        },
        {
          icon: 'tmi-person-add',
          label: 'Subscribe form',
          description: 'Allows people to subscribe to your communications',
          to: { name: 'base.services.forms.subscribe.new' },
        },
      ],
    })
  }

  const openDeleteModal = () => {
    openModal('confirmation', {
      title: 'Delete form',
      text: 'The selected form will be permanently deleted. Are you sure you would like to delete this form?',
      btnText: 'Delete',
      btnColor: 'red',
    })
  }

  return {
    openAddNewSubscribeModal,
    openAddNewModal,
    openDeleteModal,
  }
}
