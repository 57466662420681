
import { defineComponent, ref } from 'vue'
import FormsFilter from '@/components/pages/services/forms/FormsFilter.vue'
import FormsTable from '@/components/pages/services/forms/FormsTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { getTableData } from '@/services/tableService'

type TableItem = {
  name: string;
  phone: string;
  clicks: string;
  submits: string;
  embedCode: string;
}

export default defineComponent({
  components: {
    FormsFilter,
    FormsTable,
  },
  setup() {
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Name', value: 'name' },
      { text: 'Submits', value: 'submits' },
      { text: 'Embed code', value: 'embed-code', width: '468px' },
    ])
    const tableItems = ref<TableItem[]>(getTableData('servicesFormsSubscribe'))
    const selected = ref<TableItem[]>([])
    const search = ref('')

    return {
      tableHeaders,
      tableItems,
      selected,
      search,
    }
  },
})
